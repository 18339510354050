<!--
 * @Author: laizerong 1369743864@qq.com
 * @Date: 2022-07-15 15:18:50
 * @LastEditors: laizerong 1369743864@qq.com
 * @LastEditTime: 2022-09-07 20:08:43
 * @FilePath: \nuxtjs-based:\project\Aigo.OfficialWebsite.AdminWeb\src\components\page\classAdmin\configParams.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="">
    <el-form ref="classAdminParams" :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item prop="parameterType">
        <el-select v-model="formInline.parameterType" clearable placeholder="">
          <el-option label="所有参数" value=""></el-option>
          <el-option label="重要参数" :value="1"></el-option>
          <el-option label="搜索参数" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="title">
        <el-input v-model="formInline.title" clearable placeholder="参数名称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList.list"
      border
      style="width: 100%">
      <el-table-column prop="parameterItemId" label="扩展参数ID" align="center"></el-table-column>
      <el-table-column prop="title" label="参数名称" align="center"></el-table-column>
      <el-table-column prop="sort" label="排序" align="center"></el-table-column>
      <el-table-column prop="attributes" label="参数属性" align="center">
        <template slot-scope="{row}">
          <span v-if="row.parameterType == '1'">重要参数</span>
          <span v-if="row.parameterType == '0'">搜索参数</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        style="margin-top: 10px"
        background
        @current-change="handleCurrentChange"
        :current-page="formInline.page"
        :page-size="formInline.limit"
        layout="total, prev, pager, next, jumper"
        :total="dataList.total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ConfigParams',
  props: {
    goodsClassifyId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formInline: {
        page: 1,
        limit: 10,
        parameterType: '',
        goodsClassifyId: '',
        title: ''
      },
      dataList: {
        total: 0,
        list: []
      }
    }
  },
  mounted() {
    this.formInline.goodsClassifyId = this.goodsClassifyId
    this.getList()
  },
  methods: {
    handleCurrentChange(page) {
      this.formInline.page = page
      this.getList()
    },
    getList() {
      this.$store.dispatch('getClassifyParams',this.formInline).then(res => {
        this.dataList = res
      })
    },
    // 查询
    search() {
      this.getList()
    },
    // 重置
    reset() {
      this.$nextTick(() => {
        this.$refs.classAdminParams.resetFields()
        this.getList()
      })
      
    },
  }
}
</script>