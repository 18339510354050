<!--
 * @Author: laizerong 1369743864@qq.com
 * @Date: 2022-07-15 15:33:21
 * @LastEditors: laizerong 1369743864@qq.com
 * @LastEditTime: 2022-07-28 17:40:55
 * @FilePath: \nuxtjs-based:\project\Aigo.OfficialWebsite.AdminWeb\src\components\page\classAdmin\edit.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="">
    <div class="item">
      <div class="label">类目名称：</div>
      <div class="value">{{infoData.title}}</div>
    </div>
    <div class="item">
      <div class="label">所属目录：</div>
      <div class="value">{{row.parentTitle}}</div>
    </div>
    <div class="item">
      <div class="label">目录层级：</div>
      <div class="value">{{infoData.level}}</div>
    </div>
    <div class="item">
      <div class="label">品牌：</div>
      <div class="value">{{infoData.brandCode}}</div>
    </div>
    <div class="item">
      <div class="label">排序：</div>
      <div class="value">{{infoData.sort}}</div>
    </div>
    <div class="item">
      <div class="label">国家：</div>
      <div class="value">{{countryName}}</div>
    </div>
      <div class="item">
          <div class="label">图片类型顺序：</div>
          <div class="value">
              <el-radio-group v-model="infoData.customStatus" :disabled="row.haveChild">
                  <el-radio :label="true">启用</el-radio>
                  <el-radio :label="false">禁用</el-radio>
              </el-radio-group>
          </div>
          <div class="custom-el-link">
              <el-link  type="primary" @click="openCustomDialog()" :disabled="row.haveChild">自定义</el-link>
          </div>
      </div>
    <div class="item-upload">
      <div class="label">图片：</div>
      <div class="value">
        <el-upload
          class="upload-demo"
          ref="classAdmminImg"
          :action="ossInfo.ossSignature.host"
          :auto-upload="false"
          :on-remove="handleRemove"
          :file-list="imageList"
          :multiple="false"
          :on-change="uploadChange"
          list-type="picture"
          :data="fileDataObj"
          accept=".jpg, .jpeg, .png, .gif">
          <el-button size="small" type="primary">上传图片</el-button>
        </el-upload>
      </div>
    </div>
    <div class="item-upload">
      <div class="label">图标：</div>
      <div class="value">
        <el-upload
          class="upload-demo"
          ref="classAdmminIcon"
          :action="ossInfo.ossSignature.host"
          :auto-upload="false"
          :on-remove="iconHandleRemove"
          :file-list="iconList"
          :multiple="false"
          list-type="picture"
          :on-change="iconUploadChange"
          :data="iconFileDataObj"
          accept=".jpg, .jpeg, .png, .gif">
          <el-button size="small" type="primary">上传图片</el-button>
        </el-upload>
      </div>
    </div>
    <div class="btns">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="confirm" :loading="loading">确定</el-button>
    </div>

      <el-dialog
          title="自定义"
          :visible.sync="customDialogVisible"
          v-if="customDialogVisible"
          :append-to-body="true"
          width="20%"
      >
          <el-table
              :data="tableData"
              class="form_table"
              border
              fit
              highlight-current-row>
              <el-table-column
                  prop="sort"
                  label="拖拽更换顺序"
                 align="center">
                  <i class=" el-icon-sort drag-btn"></i>
              </el-table-column>
              <el-table-column
                  prop="desc"
                  label="图片类型"
              align="center">
              </el-table-column>
          </el-table>

          <div class="btns">
              <el-button @click="cancelCustomDialog">取消</el-button>
              <el-button type="primary" @click="confirmCustomDialog" :loading="loading">确定</el-button>
          </div>
      </el-dialog>
  </div>


</template>
<script>
import X2JS from 'x2js'; //解析xml
import Sortable from "sortablejs";
export default {
  name: 'Edit',
  props: {
    goodsClassifyId: {
      type: String,
      default: ''
    },
    row: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      infoData: {},
      fileDataObj: {}, // 图片
      iconFileDataObj: {}, // 图标
      ossInfo: {
        ossHost: process.env.VUE_APP_OSS_Head,
        ossSignature: { host: '' },
        activityTranslates: [],
        langs: [],
        seoIndex: '0',
        newFiles: []
      },
      ossHost: process.env.VUE_APP_OSS_Head,
      imageList: [],
      iconList: [],
      imageFile: '',
      iconFile: {},
      tableData: [],
      customDialogVisible: false
    }
  },
  computed: {
    countryName() {
      if(this.row) {
        return this.row.countryNames.join('/')
      }
    }
  },
  mounted() {
    if(this.goodsClassifyId) {
      this.getInfo()
    }
    this.getOssSignature()
  },
    created() {
        document.body.ondrop = function(event){
            event.preventDefault();
            event.stopPropagation();
        }

    },
    methods: {
// 行拖拽
      rowDrop() {
          this.$nextTick(() => {
              this.sortable1 = Sortable.create(
                  document.querySelector(".form_table .el-table__body-wrapper tbody"),
                  {
                      handle: ".drag-btn",
                      onEnd: ({ newIndex, oldIndex }) => {
                          this.tableData.splice(
                              newIndex,
                              0,
                              this.tableData.splice(oldIndex, 1)[0]
                          );
                          var newArray = this.tableData.slice(0);
                          this.tableData = [];
                          this.$nextTick(function () {
                              this.tableData = newArray;
                          });
                      },
                  }
              );
          });
      },

    // 获取详情
    getInfo() {
      this.$store.dispatch('getClassifyInfo', {id: this.goodsClassifyId}).then(res => {
        this.infoData = res
        if(res.backgroundImage != '') {
          this.imageList = [{name: '', url: res.backgroundImage}]
        }
        if(res.icon != '') {
          this.iconList = [{name: '', url: res.icon}]
        }
      })
    },
    // 图片上传状态改变，添加、上传成功、上传失败都会调用
    uploadChange(file, fileLis) {
      console.log(file)
      if (fileLis.length > 1 && file.status !== "fail") {
        fileLis.splice(0, 1);
      } else if (file.status === "fail") {
        fileLis.splice(0, 1);
      }
      const isLt = file.size / 1024 / 1024 <= 5;
      if (!isLt) {
        this.$message.error('上传图片大小不能超过 5MB!');
        fileLis.splice(0, 1);
        return;
      }
      let dataObj = {
        name: file.name,
        key: this.ossInfo.ossSignature.dir + '/' + new Date().getFullYear() + '/' + this.randomString() + this.getSuffix(file.name),
        policy: this.ossInfo.ossSignature['policy'],
        OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
        success_action_status: '201',
        callback: '',
        signature: this.ossInfo.ossSignature['signature']
        // file: file.file,
      };
      // file.type = 'add';
      this.fileDataObj = dataObj;
      let that = this;
      setTimeout(() => {
        this.$refs.classAdmminImg.submit();
        var x2js = new X2JS();
        var jsonObj = x2js.xml2js(file.response);
        let ossUrl = jsonObj.PostResponse.Location;
        this.infoData.backgroundImage = ossUrl
        // this.imageList = [{name: file.name,url: ossUrl}];
          // that.event();
      }, 500);
    },
    iconUploadChange(file, fileLis) {
      if (fileLis.length > 1 && file.status !== "fail") {
        fileLis.splice(0, 1);
      } else if (file.status === "fail") {
        fileLis.splice(0, 1);
      }
      const isLt = file.size / 1024 / 1024 <= 5;
      if (!isLt) {
        this.$message.error('上传图片大小不能超过 5MB!');
        fileLis.splice(0, 1);
        return;
      }
      let dataObj = {
        name: file.name,
        key: this.ossInfo.ossSignature.dir + '/' + new Date().getFullYear() + '/' + this.randomString() + this.getSuffix(file.name),
        policy: this.ossInfo.ossSignature['policy'],
        OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
        success_action_status: '201',
        callback: '',
        signature: this.ossInfo.ossSignature['signature']
        // file: file.file,
      };
      // file.type = 'add';
      this.iconFileDataObj = dataObj;
      this.iconFile = file
      let that = this;
      setTimeout(() => {
        this.$refs.classAdmminIcon.submit();
        var x2js = new X2JS();
        var jsonObj = x2js.xml2js(file.response);
        let ossUrl = jsonObj.PostResponse.Location;
        this.infoData.icon = ossUrl
        // that.imageUrl = [ossUrl];
          // that.event();
      }, 500);
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
      this.infoData.backgroundImage = ''
    },
    iconHandleRemove(file, fileList) {
      this.infoData.icon = ''
    },
    confirm() {
      this.loading = true
      const params = (({
        status,
        title,
        parentId,
        brandCode,
        sort,
        icon,
        backgroundImage,
        countryCode,
        customStatus,
      }) => ({
        status,
        title,
        parentId,
        brandCode,
        sort,
        icon,
        backgroundImage,
        countryCode,
        customStatus,
      }))(this.infoData)

      params.customPictureSortConfigList = this.row.customPictureSortConfigList
      params.goodsClassifyId = this.goodsClassifyId
        params.haveChild = this.row.haveChild
      this.$store.dispatch('uploadClassify',params).then(res => {
        if(res == '') {
          this.loading = false
          this.$emit('confirm')
          this.$message.success('编辑成功');
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
      })
    },
    //获取OSS签名
    getOssSignature() {
      this.$store.dispatch('getSignature', {
        dir: 'b2b'
      }).then((res) => {
        this.ossInfo.ossSignature = res;
        this.ossInfo.ossSignature.host = this.ossInfo.ossHost;
      });
    },
    /**
     * 生成随机文件名
     * @param len
     * @returns {string}
     */
    randomString(len) {
      len = len || 32;
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      let maxPos = chars.length;
      let pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    getSuffix(filename) {
      let pos = filename.lastIndexOf('.');
      let suffix = '';
      if (pos != -1) {
        suffix = filename.substring(pos);
      }
      return suffix;
    },
    cancel() {
      this.$emit('cancel')
    },
      confirmCustomDialog() {
          this.customDialogVisible = false
          this.row.customPictureSortConfigList = this.tableData
      },
      cancelCustomDialog() {
          this.customDialogVisible = false
          this.tableData = []
      },
    openCustomDialog(){
        this.customDialogVisible = true
        let params = {goodsClassifyId: this.goodsClassifyId}
        if(this.row.customPictureSortConfigList){
            this.tableData = [...this.row.customPictureSortConfigList]
        }else{
            // 查询类目的自定义图片顺序配置
            this.$store.dispatch('listCustomPictureSortConfig', params).then((res) => {
                this.tableData = res
            });
        }
        this.rowDrop()
    }
  }
}
</script>
<style scoped>
.item {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}
.item-upload {
  display: flex;
  padding-bottom: 15px;
}
.btns {
  text-align: center;
    margin-top: 20px;
}
.custom-el-link{
    margin-left: 30px;
}
.drag-btn{
    cursor: pointer;
}
</style>